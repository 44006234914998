import { CANVAS_FABRICS } from './material-constants';
import { DesignOption, GetImagesResponse, ImageWarning } from '../../types';
import { DEFAULT_DESIGN_OPTIONS, TEMPLATE_NAME, MAPPED_OPTIONS } from './constants';

const getDesignOptionsComponents = (designOptions: DesignOption[]) => {
  let waistbandStyle: DesignOption | null = null;
  let sidePocket: DesignOption | null = null;
  let button: DesignOption | null = null;
  let backPocket: DesignOption | null = null;
  let bottomFinish: DesignOption | null = null;
  let waistbandFormalTreatment: DesignOption | null = null;
  let sideStripeFormalTreatment: DesignOption | null = null;
  let smokingColor: DesignOption | null = null;

  designOptions.forEach((designOption) => {
    switch (designOption.typeCode) {
      case MAPPED_OPTIONS.waistbandStyle.typeCode:
        waistbandStyle = designOption;
        break;
      case MAPPED_OPTIONS.sidePocket.typeCode:
        sidePocket = designOption;
        break;
      case MAPPED_OPTIONS.button.typeCode:
        button = designOption;
        break;
      case MAPPED_OPTIONS.backPocket.typeCode:
        backPocket = designOption;
        break;
      case MAPPED_OPTIONS.bottomFinish.typeCode:
        bottomFinish = designOption;
        break;
      case MAPPED_OPTIONS.waistbandFormalTreatment.typeCode:
        waistbandFormalTreatment = designOption;
        break;
      case MAPPED_OPTIONS.sideStripeFormalTreatment.typeCode:
        sideStripeFormalTreatment = designOption;
        break;
      case MAPPED_OPTIONS.smokingColor.typeCode:
        smokingColor = designOption;
        break;
    }
  });

  return {
    waistbandStyle,
    sidePocket,
    button,
    backPocket,
    bottomFinish,
    waistbandFormalTreatment,
    sideStripeFormalTreatment,
    smokingColor,
  };
};

const EXT = {
  button: 'webp',
  fabric: 'webp',
  lining: 'webp',
  smokingColor: 'webp',
};

export const getPantsImages = (url: string, designOptions: DesignOption[], fabricCode?: string, _?: string): GetImagesResponse => {
  const baseUrl = url + '/Pants';
  const images: GetImagesResponse['images'] = [];
  const warnings: ImageWarning[] = [];
  const designOptionComponents = getDesignOptionsComponents(designOptions);

  let mappedFabric = CANVAS_FABRICS[fabricCode];
  if (!mappedFabric) {
    mappedFabric = CANVAS_FABRICS[DEFAULT_DESIGN_OPTIONS.FABRIC];
    warnings.push({ key: 'FABRIC', status: 'FALLBACK' });
  }

  const mappedBottomFinish = MAPPED_OPTIONS.bottomFinish.codes[designOptionComponents.bottomFinish?.code];

  if (mappedBottomFinish) {
    images.push({
      key: MAPPED_OPTIONS.bottomFinish.typeCode,
      url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.bottomFinish.typeCode}_${mappedBottomFinish.id}.${EXT.fabric}`,
    });
    if (!mappedBottomFinish.original) {
      warnings.push({ key: MAPPED_OPTIONS.bottomFinish.typeCode, status: 'FALLBACK' });
    }
  } else {
    warnings.push({ key: MAPPED_OPTIONS.bottomFinish.typeCode, status: 'NOT_FOUND' });
  }

  const mappedSidePocket =
    MAPPED_OPTIONS.sidePocket.codes.includes(designOptionComponents.sidePocket?.code) && designOptionComponents.sidePocket?.code;

  if (mappedSidePocket) {
    images.push({
      key: MAPPED_OPTIONS.sidePocket.typeCode,
      url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.sidePocket.typeCode}_${mappedSidePocket}.${EXT.fabric}`,
    });
  } else {
    warnings.push({ key: MAPPED_OPTIONS.sidePocket.typeCode, status: 'NOT_FOUND' });
  }

  const mappedBackPocket =
    MAPPED_OPTIONS.backPocket.codes.includes(designOptionComponents.backPocket?.code) && designOptionComponents.backPocket?.code;

  if (mappedBackPocket) {
    if (mappedBackPocket === MAPPED_OPTIONS.backPocket.jettedWithButton) {
      images.push({
        key: MAPPED_OPTIONS.backPocket.typeCode + '_base',
        url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.backPocket.typeCode}_${MAPPED_OPTIONS.backPocket.jettedBase}.${EXT.fabric}`,
      });
    }
    images.push({
      key: MAPPED_OPTIONS.backPocket.typeCode,
      url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.backPocket.typeCode}_${mappedBackPocket}.${EXT.fabric}`,
    });
  } else {
    warnings.push({ key: MAPPED_OPTIONS.backPocket.typeCode, status: 'NOT_FOUND' });
  }

  images.push({
    key: 'base',
    url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_base_${MAPPED_OPTIONS.waistbandStyle.typeCode}.${EXT.fabric}`,
  });

  let sideStripeformalTreatmentLayer = '';
  if (
    !designOptionComponents.sideStripeFormalTreatment ||
    designOptionComponents.sideStripeFormalTreatment.code === MAPPED_OPTIONS.sideStripeFormalTreatment.no
  ) {
    sideStripeformalTreatmentLayer = `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.sideStripeFormalTreatment.typeCode}_empty.${EXT.fabric}`;
  } else {
    if (mappedBottomFinish) {
      if (!MAPPED_OPTIONS.smokingColor.codes.includes(designOptionComponents.smokingColor?.code)) {
        warnings.push({ key: MAPPED_OPTIONS.smokingColor.typeCode, status: 'FALLBACK' });
      }
      sideStripeformalTreatmentLayer = `${baseUrl}/Pant Formal Treatment/stain black___${TEMPLATE_NAME}_${
        MAPPED_OPTIONS.sideStripeFormalTreatment?.typeCode
      }_${MAPPED_OPTIONS.sideStripeFormalTreatment?.typeCode}${mappedBottomFinish.id.slice(-2)}.${EXT.smokingColor}`;
    } else {
      warnings.push({ key: MAPPED_OPTIONS.sideStripeFormalTreatment.typeCode, status: 'NOT_FOUND' });
    }
  }

  images.push({
    key: MAPPED_OPTIONS.sideStripeFormalTreatment.typeCode,
    url: sideStripeformalTreatmentLayer,
  });

  let mappedWaistBandStyle: string | undefined;
  let formalTreatmentLayer = '';
  if (designOptionComponents.waistbandFormalTreatment) {
    if (designOptionComponents.waistbandFormalTreatment.code === MAPPED_OPTIONS.waistbandFormalTreatment.yes) {
      if (!MAPPED_OPTIONS.smokingColor.codes.includes(designOptionComponents.smokingColor?.code)) {
        if (!warnings.some((w) => w.key === MAPPED_OPTIONS.smokingColor.typeCode)) {
          warnings.push({ key: MAPPED_OPTIONS.smokingColor.typeCode, status: 'FALLBACK' });
        }
      }
      formalTreatmentLayer = `${baseUrl}/Pant Formal Treatment/stain black___${TEMPLATE_NAME}_${MAPPED_OPTIONS.waistbandFormalTreatment.typeCode}_${MAPPED_OPTIONS.waistbandFormalTreatment.yes}.${EXT.fabric}`;
    } else {
      formalTreatmentLayer = `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.waistbandFormalTreatment.typeCode}_empty.${EXT.fabric}`;

      mappedWaistBandStyle =
        MAPPED_OPTIONS.waistbandStyle.codes.includes(designOptionComponents.waistbandStyle?.code) && designOptionComponents.waistbandStyle?.code;

      if (mappedWaistBandStyle) {
        images.push({
          key: MAPPED_OPTIONS.waistbandStyle.typeCode,
          url: `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${MAPPED_OPTIONS.waistbandStyle.typeCode}_${mappedWaistBandStyle}.${EXT.fabric}`,
        });
      } else {
        warnings.push({ key: MAPPED_OPTIONS.waistbandStyle.typeCode, status: 'NOT_FOUND' });
      }
    }

    images.push({
      key: MAPPED_OPTIONS.waistbandFormalTreatment.typeCode,
      url: formalTreatmentLayer,
    });
  } else {
    warnings.push({ key: MAPPED_OPTIONS.waistbandFormalTreatment.typeCode, status: 'NOT_FOUND' });
  }

  let mappedButton = '';

  if (MAPPED_OPTIONS.button.codes.includes(designOptionComponents.button?.code)) {
    mappedButton = designOptionComponents.button.code;
  } else {
    warnings.push({ key: MAPPED_OPTIONS.button.typeCode, status: 'FALLBACK' });
    mappedButton = DEFAULT_DESIGN_OPTIONS.T010505;
  }

  const noHoleButton = MAPPED_OPTIONS.button.buttonsWithoutHoles.includes(designOptionComponents.button?.code);

  let waistBandbuttonLayer = '';

  if (mappedWaistBandStyle && MAPPED_OPTIONS.waistbandStyle.codesWithButtons.includes(designOptionComponents.waistbandStyle?.code)) {
    if (mappedButton === MAPPED_OPTIONS.button.fabricButton) {
      waistBandbuttonLayer = `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${
        MAPPED_OPTIONS.button.typeCode
      }_${mappedWaistBandStyle}${noHoleButton ? '_without_hole' : ''}.${EXT.fabric}`;
    } else {
      waistBandbuttonLayer = `${baseUrl}/button/${mappedButton}/${mappedButton}___${TEMPLATE_NAME}_${
        MAPPED_OPTIONS.button.typeCode
      }_${mappedWaistBandStyle}${noHoleButton ? '_without_hole' : ''}.${EXT.button}`;
    }

    if (mappedButton) {
      images.push({
        key: MAPPED_OPTIONS.waistbandStyle.typeCode + '_button',
        url: waistBandbuttonLayer,
      });
    }
  }

  let backPocketButtonLayer = '';

  if (mappedBackPocket && MAPPED_OPTIONS.backPocket.codesWithButtons.includes(designOptionComponents.backPocket?.code)) {
    if (mappedButton === MAPPED_OPTIONS.button.fabricButton) {
      backPocketButtonLayer = `${baseUrl}/fabric/${mappedFabric}/${mappedFabric}___${TEMPLATE_NAME}_${
        MAPPED_OPTIONS.button.typeCode
      }_${mappedBackPocket}${noHoleButton ? '_without_hole' : ''}.${EXT.fabric}`;
    } else {
      backPocketButtonLayer = `${baseUrl}/button/${mappedButton}/${mappedButton}___${TEMPLATE_NAME}_${
        MAPPED_OPTIONS.button.typeCode
      }_${mappedBackPocket}${noHoleButton ? '_without_hole' : ''}.${EXT.button}`;
    }

    if (mappedButton) {
      images.push({
        key: MAPPED_OPTIONS.backPocket.typeCode + '_button',
        url: backPocketButtonLayer,
      });
    }
  }

  return { images, warnings };
};
