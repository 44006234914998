import { values } from 'lodash';

export const TEMPLATE_NAME = 'pants_design';

export const DEFAULT_DESIGN_OPTIONS = {
  FABRIC: 'TTV2120',
  T010505: 'T11', //buttons
  T010504: 'T25', // smoking color
};

export const MAPPED_OPTIONS = {
  waistbandStyle: {
    typeCode: 'T010509',
    codesWithButtons: ['T01050916'],
    codes: [
      'T01050901', // Belt loops
      'T01050902', // Buckle and strap
      'T01050903', // Without belt loops or buckle and strap
      'T01050916', // 4.5cm Wide Extension with 2 Btn
    ],
  },
  sidePocket: {
    typeCode: 'T010510',
    codes: [
      'T01051001', // Slanted
      'T01051002', // On seam
      'T01051003', // Jetted off seam
      'T01051006', // Jean pocket
    ],
  },
  backPocket: {
    typeCode: 'T010512',
    codesWithButtons: ['T01051203', 'T01051205', 'T01051206'],
    jettedWithButton: 'T01051205',
    jettedBase: 'T01051204',
    codes: [
      'T01051201', // None
      'T01051202', // Welted without button
      'T01051203', // loop and button
      'T01051204', // Jetted without button
      'T01051206', // Flap and button
      'T01051208', // Patch
      'T01051205', // Jetted with button
    ],
  },
  bottomFinish: {
    typeCode: 'T010517',
    codes: {
      T01051701: { id: 'T01051701', original: true }, // Plain hem with heel tape
      T01051704: { id: 'T01051704', original: true }, // Cuffs 4cm/1.6in
      T01051705: { id: 'T01051705', original: true }, // Cuffs 5cm/2in
      T01051703: { id: 'T01051704', original: false },
      T01051710: { id: 'T01051704', original: false },
      T01051707: { id: 'T01051705', original: false },
      T01051709: { id: 'T01051701', original: true }, // its not original but still you cannot see it anyways
      T01051706: { id: 'T01051701', original: true }, // its not original but still you cannot see it anyways
      T01051702: { id: 'T01051701', original: false },
    },
  },
  waistbandFormalTreatment: {
    typeCode: 'T010534',
    yes: 'T01053402',
    no: 'T01053401',
  },
  sideStripeFormalTreatment: {
    typeCode: 'T010531',
    no: 'T01053101',
    codes: [
      'T01053102', // 1.5cm Narrow Stripe
    ],
  },
  smokingColor: {
    typeCode: 'T010504',
    codes: [
      'TU25', // Black
    ],
  },
  button: {
    typeCode: 'T010505', // Button
    fabricButton: 'Self Fabric Buttons',
    codes: [
      'Self Fabric Buttons',
      'T10',
      'T100',
      'T101',
      'T102',
      'T103',
      'T104',
      'T105',
      'T106',
      'T107',
      'T108',
      'T109',
      'T11',
      'T110',
      'T111',
      'T112',
      'T113',
      'T114',
      'T115',
      'T116',
      'T117',
      'T118',
      'T119',
      'T12',
      'T120',
      'T121',
      'T122',
      'T123',
      'T124',
      'T125',
      'T126',
      'T127',
      'T128',
      'T129',
      'T130',
      'T131',
      'T132',
      'T133',
      'T134',
      'T135',
      'T136',
      'T137',
      'T138',
      'T139',
      'T140',
      'T141',
      'T142',
      'T143',
      'T20',
      'T21',
      'T23',
      'T26',
      'T27',
      'T28',
      'T29',
      'T3',
      'T30',
      'T31',
      'T4',
      'T40',
      'T41',
      'T42',
      'T44',
      'T46',
      'T47',
      'T48',
      'T50',
      'T51',
      'T52',
      'T53',
      'T54',
      'T55',
      'T56',
      'T59',
      'T6',
      'T60',
      'T61',
      'T62',
      'T63',
      'T64',
      'T65',
      'T67',
      'T69',
      'T7',
      'T70',
      'T71',
      'T72',
      'T73',
      'T74',
      'T75',
      'T76',
      'T77',
      'T78',
      'T79',
      'T80',
      'T81',
      'T82',
      'T83',
      'T85',
      'T86',
      'T87',
      'T88',
      'T89',
      'T9',
      'T90',
      'T91',
      'T92',
      'T93',
      'T95',
      'T96',
      'T97',
      'T98',
      'T99',
      'TG05',
      'TG06',
      'TOC01',
      'TOC02',
      'TOC03',
      'TOC04',
      'TOC05',
      'TOC06',
      'TOC07',
      'TOC08',
      'TOC09',
      'TOC10',
      'TOC11',
      'TOC12',
      'TOC13',
      'TOC14',
      'TOC15',
      'TOC16',
      'TSN54',
      'TUB24',
      'TUB25',
      'TUB26',
      'TUB27',
      'TUB28',
      'TUB29',
      'TUB30',
      'TUB31',
      'TUB32',
      'TUB33',
      'TUB34',
      'TUB35',
      'TUB36',
      'TUB38',
      'TUB39',
      'TUB40',
      'TXB13',
      'TXB14',
      'TXB15',
      'TXB16',
      'TXB19',
      'TXB20',
      'TXB21',
    ],
    buttonsWithoutHoles: [
      'Self Fabric Buttons',
      'T44',
      'T46',
      'T47',
      'T48',
      'T50',
      'T51',
      'T69',
      'T70',
      'T93',
      'T95',
      'T96',
      'T97',
      'T98',
      'T99',
      'T101',
      'T102',
      'T103',
      'T104',
      'T105',
      'T106',
      'T108',
      'T109',
      'T110',
      'T111',
      'T112',
      'T113',
      'T114',
      'TG05',
      'TG06',
      'TG09',
      'TSN54',
      'TUB24',
      'TUB25',
      'TUB26',
      'TUB27',
      'TUB28',
      'TUB29',
      'TUB30',
      'TUB31',
      'TUB32',
      'TUB33',
      'TUB34',
      'TUB35',
      'TUB36',
      'TUB37',
      'TUB38',
      'TUB39',
      'TUB40',
      'TXB13',
      'TXB14',
      'TXB15',
      'TXB16',
      'TXB19',
      'TXB20',
      'TXB21',
    ],
  },
};

export const TYPE_OPTIONS_WITH_PREVIEW = {
  [MAPPED_OPTIONS.waistbandStyle.typeCode]: MAPPED_OPTIONS.waistbandStyle.codes,
  [MAPPED_OPTIONS.sidePocket.typeCode]: MAPPED_OPTIONS.sidePocket.codes,
  [MAPPED_OPTIONS.button.typeCode]: MAPPED_OPTIONS.button.codes,
  [MAPPED_OPTIONS.backPocket.typeCode]: MAPPED_OPTIONS.backPocket.codes,
  [MAPPED_OPTIONS.bottomFinish.typeCode]: values(MAPPED_OPTIONS.bottomFinish.codes)
    .filter(({ original }) => original)
    .map(({ id }) => id),
  [MAPPED_OPTIONS.waistbandFormalTreatment.typeCode]: [MAPPED_OPTIONS.waistbandFormalTreatment.no, MAPPED_OPTIONS.waistbandFormalTreatment.yes],
  [MAPPED_OPTIONS.sideStripeFormalTreatment.typeCode]: [
    MAPPED_OPTIONS.sideStripeFormalTreatment.no,
    ...MAPPED_OPTIONS.sideStripeFormalTreatment.codes,
  ],
  [MAPPED_OPTIONS.smokingColor.typeCode]: MAPPED_OPTIONS.smokingColor.codes,
};
